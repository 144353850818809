<template>
<div id="merchant">
  <div class="merchant-box" v-show="merchantInfo.applicationStatus!=0">
    <div class="merchant-left">
      <router-link :to="{path:'/home'}"><h3><i class="el-icon-arrow-left"></i><span>返回首页</span></h3></router-link>
      <p @click="toggle(1)" :class="{active:sub==1}"><span>商户信息</span><i class="el-icon-arrow-right"></i></p>
      <p @click="toggle(2)" :class="{active:sub==2}"><span>订单信息</span><i class="el-icon-arrow-right"></i></p>
      <p @click="toggle(3)" :class="{active:sub==3}"><span>提现记录</span><i class="el-icon-arrow-right"></i></p>
      <p @click="toggle(4)" :class="{active:sub==4}"><span>详细地址</span><i class="el-icon-arrow-right"></i></p>
    </div>
    <div class="merchant-right">
      <div class="real" v-show="sub==1">
        <div class="merchant-header">商户信息</div>
        <div class="merchant-ct">
          <p><span>商户信息</span>：{{merchantInfo.serviceName}}</p>
          <p style="margin-top:25px"><span>账户金额</span>：<label>￥{{merchantInfo.accountAmount||0}}</label></p>
        </div>
      </div>
      <div class="collect"  v-show="sub==2">
         <div class="merchant-header">订单信息</div>
         <el-tabs v-model="orderStatus" @tab-click="getObtainMerchantOrders">
            <el-tab-pane label="全部" name="All"></el-tab-pane>
            <el-tab-pane label="预约订单" name="0"></el-tab-pane>
            <el-tab-pane label="待服务" name="1"></el-tab-pane>
            <el-tab-pane label="已完成" name="2"></el-tab-pane>
            <el-tab-pane label="订单取消" name="3"></el-tab-pane>
            <el-tab-pane label="退款售后" name="4"></el-tab-pane>
          </el-tabs>
          <div class="orderList">
            <el-table
              :data="orderList"
              style="width: 100%">
              <el-table-column
                prop="placeOrderUserName"
                label="下单人"
                align="center"
               />
              <el-table-column
                prop="serviceGoodsPriceName"
                label="服务名称"
                align="center"
               />
              <el-table-column
                label="订单价格"
                align="center"
              >
                <template slot-scope="scope">
                   ￥{{scope.row.orderPrice}}
                </template>
              </el-table-column>
              <el-table-column
                prop="liaisonNan"
                label="联系人"
                align="center"
               />
              <el-table-column
                prop="arrivalTime"
                label="预约日期"
                align="center"
              />
              <el-table-column
                prop="toDoorTime"
                label="到门时间"
                align="center"
              >
                <template slot-scope="scope">
                   <span v-show="scope.row.toDoorTime==1">8:00-12:00</span>
                   <span v-show="scope.row.toDoorTime==2">12:00-15:00</span>
                   <span v-show="scope.row.toDoorTime==3">15:00-18:00</span>
                </template>
             </el-table-column>
             <el-table-column
                prop="toDoorTime"
                label="订单状态"
                align="center"
              >
                <template slot-scope="scope">
                   <span v-show="scope.row.orderStatus==0">预约订单</span>
                   <span v-show="scope.row.orderStatus==1">待服务</span>
                   <span v-show="scope.row.orderStatus==2">已完成</span>
                   <span v-show="scope.row.orderStatus==3">订单取消</span>
                   <span v-show="scope.row.orderStatus==4">退款</span>
                </template>
             </el-table-column>
               <el-table-column
                prop="toDoorTime"
                label="操作"
                width="80"
                align="center"
              >
                <template slot-scope="scope">
                   <span class="operate" @click="openOrder(scope.row)">查看</span>
                </template>
             </el-table-column>
            </el-table>
          </div>
      </div>
      <div class="foot"  v-show="sub==3">
         <div class="merchant-header">提现记录</div>
         <div class="record">开发中。。。</div>
      </div>
      <div class="real" v-show="sub==4">
        <div class="merchant-header">详细地址</div>
        <div class="companyMap">
          <el-amap-search-box  class="search-box"  :search-option="searchOption"  :on-search-result="onSearchResult">
          </el-amap-search-box>

          <el-amap ref="map"  vid="amapDemo" :events="events" zoom="18" :center="center"  class="amap-demo">
            <el-amap-marker key="100" :position="center"></el-amap-marker>
          </el-amap>
          <div class="map-btn" @click="setMap">设置商户地址</div>
        </div>
      </div>
    </div>
    <div class="payDialog">
      <el-dialog
        title="订单详情"
        :visible.sync="orderShow"
        width="600px"
       >  
       <el-form ref="form" label-width="100px">
        <el-form-item label="服务名称:">
           {{orderInfo.serviceGoodsPriceName}}
        </el-form-item>
        <el-form-item label="下单人:">
           {{orderInfo.placeOrderUserName}}
        </el-form-item>
        <el-form-item label="订单价格:">
           ￥{{orderInfo.orderPrice}}
        </el-form-item>
        <el-form-item label="联系人:">
           {{orderInfo.liaisonNan}}
        </el-form-item>
         <el-form-item label="联系人电话:">
           {{orderInfo.placeOrderPhone}}
        </el-form-item>
        <el-form-item label="地址:">
           {{orderInfo.address}}{{orderInfo.houseNumber}}
        </el-form-item>
         <el-form-item label="预约日期:">
           {{orderInfo.arrivalTime}}
        </el-form-item>
        <el-form-item label="到门时间:">
            <span v-show="orderInfo.toDoorTime==1">8:00-12:00</span>
            <span v-show="orderInfo.toDoorTime==2">12:00-15:00</span>
            <span v-show="orderInfo.toDoorTime==3">15:00-18:00</span>
        </el-form-item>
        <el-form-item label="订单状态:">
          <span v-show="orderInfo.orderStatus==0">预约订单</span>
          <span v-show="orderInfo.orderStatus==1">待服务</span>
          <span v-show="orderInfo.orderStatus==2">已完成</span>
          <span v-show="orderInfo.orderStatus==3">订单取消</span>
          <span v-show="orderInfo.orderStatus==4">退款</span>
        </el-form-item>
       </el-form>
      </el-dialog>
    </div>
  </div>
  <div class="verify" v-show="merchantInfo.applicationStatus==0">
      <h3>申请处理中</h3>
      <p>您的商户申请入驻已经提交，我们将在一个工作日内尽快审核，审核时间: 8:00-22:00</p>
      <div class="verify-ct">
        <div class="info">
          <img src="@/assets/imgs/company/companyBtn.png" alt="">
          <span>审核中</span> 
        </div>
      </div>
    </div>
</div>
</template>

<script>
import {getMerchantInfo,setMerchantAddress,getObtainMerchantOrders} from "@/api/index.js";
export default {
  name: "merchant",
  data() {
    let _this = this;
    return {
      sub: 1,
      center: [93.51452,42.82699],
      events: {
        click(e) {
           _this.center = [e.lnglat.lng, e.lnglat.lat];
           _this.getFormattedAddress();
           console.log(e)
        }
      },
      searchOption: {
        // 限制搜索城市的范围
        citylimit: false,
      },
      addressModel:{
        address:'',
        latitude:'',
        longitude:'',
        region:''
      },
      orderList:[],
      orderStatus:'All',
      payShow:false,
      orderShow:false,
      orderInfo:{},
      merchantInfo:{}
    }
  },
  mounted(){
    this.getObtainMerchantOrders()
    this.getMerchantInfo()
  },
  methods: {
    getMerchantInfo(){
      getMerchantInfo().then(res=>{
        this.merchantInfo = res.data
      })
    },
    openOrder(row){
      this.orderInfo = row
      this.orderShow = true
      console.log( this.orderInfo )
    },
    setMap(){
      this.addressModel.latitude = this.center[0]
      this.addressModel.longitude = this.center[1]
      setMerchantAddress( this.addressModel).then(res=>{
         console.log(res)
         if(res.code==200){
            this.$message({
                message: '设置成功',
                type: 'success'
            })
         }else{
           this.$message({
                message:res.msg,
                type: 'warning'
            })
         }
      })
    },
    getObtainMerchantOrders(){
      getObtainMerchantOrders({
        orderStatus:this.orderStatus
      }).then(res=>{
        this.orderList = res.rows
      })
    },
    getFormattedAddress() {
      window.AMap.plugin('AMap.Geocoder', () => {
        let GeocoderOptions = {
            city: '全国'
        };
        let geocoder = new window.AMap.Geocoder(GeocoderOptions);
        geocoder.getAddress(this.center, (status, result) => {
            console.log('通过经纬度拿到的地址', result);
            if (status === 'complete' && result.info === 'OK') {
              this.addressModel.address = result.regeocode.formattedAddress;
              this.addressModel.region = result.regeocode.addressComponent.adcode;
            } 
        });
      });
    },
    onSearchResult(pois) {
      if (pois && pois.length > 0) {
        //如果长度为1则无需转化
        let poi = pois[0];
        let lng = poi["lng"];
        let lat = poi["lat"];
        this.center = [lng, lat];
        this.getFormattedAddress()
      }
    },
    toggle(sub) {
      this.sub = sub
    }
  }
};
</script>

<style lang="scss" scoped>
#merchant {
  min-height:600px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  overflow: hidden;
  padding-bottom: 20px;
}
.companyMap{
  height: 560px;
  padding:15px;
  overflow: hidden;
  .amap-demo{
    height: 450px;
  }
  .el-vue-search-box-container {
   width: 100%;
   margin-left: 0;
   margin-bottom: 10px;
   border-top: 1px solid #eee;
  }
}
.orderList{
  margin: 0 20px;
}
.merchant-box {
  width: 1200px;
  margin: 0 auto;
  padding-top: 24px;
}

.merchant-left {
  width: 405px;
  min-height: 200px;
  background: #FFFFFF;
  float: left;
  padding-bottom: 3px;

  h3 {
    height: 54px;
    line-height: 54px;
    font-size: 18px;
    font-weight: 400;
    color: #666666;
    border-bottom: 1px solid #ddd;
    margin: 0 20px;

    span {
      display: inline-block;
      margin-left: 5px;
    }
  }

  p {
    height: 54px;
    line-height: 54px;
    font-size: 16px;
    padding-left: 20px;
    cursor: pointer;
    color: #222222;

    span {
      display: inline-block;
      width: 350px;
    }
  }

  .active {
    background: #0077FF;
    color: #fff;
  }
}

.merchant-right {
  float: left;
  width: 785px;
  min-height:543px;
  background: #FFFFFF;
  margin-left: 10px;

  .merchant-header {
    height:54px;
    line-height: 54px;
    border-bottom: 1px solid #ddd;
    margin: 0 20px;
    font-size: 18px;
    font-weight: 500;
    color: #222222;
  }

  .real {
    p {
      font-size: 16px;
      color: #999999;
      margin: 30px 20px 14px;
      width: 200px;
      float: left;
      label{
            font-size: 24px;
    font-weight: 400;
    color: #fc6c38;
      }

    }
  }
}

.submit-btn {
    width: 100px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    color: #fff;
    font-size: 16px;
    color: #FFFFFF;
    background: #0077FF;
    border-radius: 5px;
    margin-top: 10px;
    margin-left: 100px;
    cursor: pointer;
}
.map-btn{
    width:200px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 16px;
    color: #FFFFFF;
    background: #0077FF;
    border-radius: 5px;
    margin-left: 10px;
    margin-top: 15px;
    cursor: pointer;
}
.operate{
  color: #0077FF;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
}
.payDialog{

 .row-title{
      margin-top:6px;
      margin-bottom: 10px;
      overflow: hidden;
      img{
        float: left;
        width: 18px;
        height: 18px;
      }
      span{
        display: block;
        float: left;
            font-size: 14px;
    font-weight: 500;
    color: #414a60;
    margin-left: 8px;
      }
    }
.qrcode{
  img{
    width: 132px;
    margin: 20px auto;
  }
}
}
.record{
  margin-top:50px;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}
.verify {
  width: 1065px;
  min-height: 353px;
  margin: 31px auto;
  background: #FFFFFF;
  padding-top: 60px;
  border-radius: 1px 10px 10px 10px;
  overflow: hidden;
  padding-bottom: 40px;

  h3 {
    font-size: 24px;
    font-weight: bold;
    color: #222222;
    text-align: center;
    margin-bottom: 30px;
  }

  p {
    font-size: 16px;
    color: #222222;
    text-align: center;
    margin-bottom: 30px;
  }

  .verify-ct {
    width: 730px;
    height: 154px;
    background: #F5F5F5;
    border-radius: 5px;
    margin: 0 auto;
    padding: 15px 20px;

    .info {
      width: 732px;
      height: 140px;
      background: #FFFFFF;
      border-radius: 5px;
      img {
        width: 86px;
        height: 86px;
        margin: 29px 40px;
        border-radius: 50%;
        float: left;
      }

      span {
        display: block;
        float: left;
        margin-top: 60px;
        font-size: 18px;
        color: #0077FF;
      }
      
    }
  }
}
</style><style lang="scss">
#merchant{
  .el-tabs {
    padding: 0 20px;
  }
}
.payDialog{
   .el-dialog__body{
    padding:10px 30px;
  }
}
</style>
